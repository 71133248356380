(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:Championship
   *
   * @description
   *
   */
  angular
    .module('neo.services')
    .factory('Championship', Championship);

  function Championship($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/championships/:id', {id: '@_id'}, {
      update: {
        method: 'PUT'
      },
      query: {
        method: 'GET',
        isArray: false
      }
    });
  }
}());
